
import Home from "~/components/web/Home";

export default {
  data() {
    return {
      window: {
        width: 480,
        height: 480,
      },
      scrolled: false,
      scrollY: 0,
    };
  },
  head() {
    return {
      title: "Official Website Fans Timnas Indonesia",
      meta: [
        {
          hid: "description",
          name: "description",
          content: "Official Website Fans Timnas Indonesia",
        },
        { hid: "og:url", property: "og:url", content: this.$config.BASE_URL },
        { hid: "og:type", property: "og:type", content: "website" },
        {
          hid: "og:title",
          property: "og:title",
          content: "Official Website Fans Timnas Indonesia",
        },
        {
          hid: "og:description",
          property: "og:description",
          content: "Official Website Fans Timnas Indonesia",
        },
        {
          hid: "og:image",
          property: "og:image",
          content:
            "https://olahbolamedia.sgp1.digitaloceanspaces.com/kitagaruda/images/homepage1.jpg",
        },
        {
          hid: "og:image:alt",
          property: "og:image:alt",
          content: "Official Website Fans Timnas Indonesia",
        },
      ],
    };
  },
  components: {
    Home,
  },
  mounted() {
    window.addEventListener("resize", this.handleResize);
    window.addEventListener("scroll", this.handleScroll);
    this.handleResize();
  },

  destroyed() {
    window.removeEventListener("resize", this.handleResize);
    window.removeEventListener("scroll", this.handleScroll);
  },
  methods: {
    handleResize() {
      this.window.width = window.innerWidth;
      this.window.height = window.innerHeight;
    },
    handleScroll() {
      this.scrollY = window.scrollY;
      if (window.scrollY > 450) {
        this.scrolled = true;
      } else {
        this.scrolled = false;
      }
    },
  },
};
